@media only screen and (min-width: 0px) and (max-width: 1049px) {
  .page-divider {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
  }

  .products {
    width: 100%;
    min-height: 100%;
    background-color: var(--primaryBackground);
    overflow-y: scroll;
    max-height: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    padding-top: 64px;
  }

  .products_left {
    width: 100%;
  }

  .products_right {
    width: 100%;
    background-color: #f9f9f9;
    height: 100%;
    min-height: 100%;
    position: absolute;
    right: 0;
    padding-top: 30px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 24px;
  }

  .products_addNew {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-right: 24px;
    width: 100%;
    margin-bottom: 14px;
  }

  .products_addNew_button {
    border: 1px solid #dddddd;
    height: 40px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: black;
    cursor: pointer;
  }

  .products_addNew_button_active {
    border: 1px solid #dddddd;
    background-color: #ffeedb;
    height: 40px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: #f58700;
    cursor: pointer;
  }

  .products_addNew_button:hover {
    background-color: #f3f3f3;
    color: black;
  }

  .products_list {
  }

  .products_list_element {
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;

    border-bottom: 1px solid #ebebeb;
  }

  .products_list_element:hover {
    background-color: #f3f3f3;
  }

  .products_list_element_active {
    background-color: #ffeedb;
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;

    border-bottom: 1px solid #ebebeb;
  }

  .products_list_element_col {
    padding-left: 5px;
    padding-right: 5px;
  }

  .page-divider {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .inputrow {
    height: 50px;
    width: calc(100% - 24px);
    border: none;
    border-radius: 999px;
    margin-top: 12px;
  }

  .inputrow_input {
    height: 50px;
    width: 100%;
    border: 1px solid #dddddd;
    border-radius: 999px;
    padding-left: 14px;
  }

  .savebtn {
    margin-top: 24px;
    width: calc(100% - 24px);
    height: 45px;
    border-radius: 8px;
    background-color: #f58700;
    color: white;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .creator_previewimages{
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

  }
}

@media only screen and (min-width: 1050px) {
  .page-divider {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
  }

  .products {
    width: calc(100% - 180px);
    min-height: 100%;
    background-color: var(--primaryBackground);
    overflow-y: scroll;
    max-height: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .products_left {
    width: 70%;
  }

  .products_right {
    width: 30%;
    background-color: #f9f9f9;
    height: 100%;
    min-height: 100%;
    position: absolute;
    right: 0;
    padding-top: 30px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 24px;

    overflow-y: scroll;
  }

  .products_addNew {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-right: 24px;
    width: 100%;
    margin-bottom: 14px;
  }

  .products_addNew_button {
    border: 1px solid #dddddd;
    height: 40px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: black;
    cursor: pointer;
  }

  .products_addNew_button_active {
    border: 1px solid #dddddd;
    background-color: #ffeedb;
    height: 40px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: #f58700;
    cursor: pointer;
  }

  .products_addNew_button:hover {
    background-color: #f3f3f3;
    color: black;
  }

  .products_list {
  }

  .products_list_element {
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;

    border-bottom: 1px solid #ebebeb;
  }

  .products_list_element:hover {
    background-color: #f3f3f3;
  }

  .products_list_element_active {
    background-color: #ffeedb;
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;

    border-bottom: 1px solid #ebebeb;
  }

  .products_list_element_col {
    padding-left: 5px;
    padding-right: 5px;
  }

  .page-divider {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .inputrow {
    height: 50px;
    width: calc(100% - 24px);
    border: none;
    border-radius: 999px;
    margin-top: 12px;
  }

  .inputrow_input {
    height: 50px;
    width: 100%;
    border: 1px solid #dddddd;
    border-radius: 999px;
    padding-left: 14px;
  }

  .savebtn {
    margin-top: 24px;
    width: calc(100% - 24px);
    height: 45px;
    border-radius: 8px;
    background-color: #f58700;
    color: white;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .creator_previewimages{
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

  }
}
